import React from "react";
import { SiPostman } from "react-icons/si";
import { FaGitAlt, FaFigma } from "react-icons/fa";
import CommonContent from "./CommonContent";

const ToolsSkills = () => {
  const tools = [
    { icon: <FaGitAlt />, name: "Git" },
    { icon: <FaFigma />, name: "Figma" },
    { icon: <SiPostman />, name: "Postman" },
  ];

  return (
    <div className=" text-white pt-4">
      <h1 className="text-center text-2xl ">Tools</h1>

      <CommonContent skills={tools} />
    </div>
  );
};

export default ToolsSkills;
