import React from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

const ContactMe = () => {
  return (
    <div className="container 2xl:m-auto">
      <div className=" py-20 md:px-14 px-4 lg:ml-14 h-auto">
        <div className="flex items-center justify-center flex-col text-center">
          <h1 className="text-white text-center text-3xl mb-4 font-bold">
            Contact Me
          </h1>
          <hr className="border-[2px] border-[#29C195] w-[90px]" />
        </div>

        <div className="lg:flex mt-14">
          <ContactInfo />
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
