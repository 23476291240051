import React from "react";
import MainLayout from "../../layouts/MainLayout";
import MainSection from "../../components/skills/MainSection";

const Skills = () => {
  return (
    <MainLayout>
      <MainSection />
    </MainLayout>
  );
};

export default Skills;
