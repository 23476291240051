import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { FiGithub } from "react-icons/fi";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ContactInfo = () => {
  const accounts = [
    { icon: FiGithub, link: "https://github.com/FatimaMN85" },
    { icon: FaLinkedinIn, link: "https://www.linkedin.com/in/fatimamoumni" },
    { icon: FaXTwitter, link: "https://x.com/FatimaMoumni5" },
  ];

  return (
    <div className="lg:w-[50%] flex justify-center flex-col items-center">
      <div className="lg:pr-8 ">
        <div className="border-[1px] border-[#ffffff1a] flex flex-col justify-center items-center rounded-lg p-6 w-[100%] bg-[#0f172a1a]">
          <div className="p-2 bg-[#00c6bf42] rounded-full text-center flex justify-center items-center w-[50px] h-[50px]">
            <TfiEmail className="text-[#00C6BF] text-2xl" />
          </div>
          <div className="text-white ml-4">
            <h4 className="text-center text-[#00C6BF] my-3">Contact on mail</h4>
            <p className="mt-2">moumnifatima814@gmail.com</p>
          </div>
        </div>

        <div className="border-[1px] border-[#ffffff1a] lg:mt-14 mt-8 rounded-lg p-6 w-[100%] bg-[#0f172a1a]">
          <h4 className="text-[#00C6BF] text-center">Find Me On</h4>

          <p className="text-white text-2xl mt-3 flex justify-center items-center">
            {accounts.map((account, index) => (
              <Link
                key={index}
                to={account.link}
                target="_blank"
                className="p-2 bg-[#00c6bf42] hover:bg-none hover:text-white hover:border-2 hover:border-[#00c6bf42] text-[#00C6BF] mr-4 rounded-full text-center flex justify-center items-center w-[50px] h-[50px]"
              >
                <account.icon />
              </Link>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
