import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC7DCX7OR6XYom5LH14I-iVy60gO85FjTE",
  authDomain: "portfolio-c6a7c.firebaseapp.com",
  databaseURL:
    "https://portfolio-c6a7c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-c6a7c",
  storageBucket: "portfolio-c6a7c.appspot.com",
  messagingSenderId: "70501481904",
  appId: "1:70501481904:web:d35e94699dfe0bd8f4aa75",
  measurementId: "G-K5G39DMK9N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export { database };
