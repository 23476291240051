import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_CONTACT_FORM_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      console.log(form.current);
      await emailjs.sendForm(serviceId, templateId, form.current, publicKey);
      console.log("SUCCESS!");
    } catch (error) {
      console.log("FAILED...", error);
    }
    e.target.reset();
  };

  return (
    <div className="lg:w-[50%] mt-8 lg:mt-0 ">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="lg:px-8 lg:py-4 px-4 py-3 bg-[#ffffff0d] w-[100%] border-[1px] border-[#ffffff1a] rounded-lg"
      >
        <div className="my-6 text-white w-[100%]">
          <label className="block mb-3">Name</label>
          <input
            className="border-[1px] border-[#ffffff1a] w-[100%] bg-[#0f172a1a] rounded-lg text-white p-3 outline-0"
            placeholder="Enter Your Name"
            type="text"
            required
            name="name"
          />
        </div>

        <div className="my-6 text-white w-[100%]">
          <label className="block mb-3">Email</label>
          <input
            className="border-[1px] border-[#ffffff1a] w-[100%] bg-[#0f172a1a] rounded-lg text-white p-3 outline-0"
            placeholder="Enter Your Email"
            type="email"
            required
            name="email"
          />
        </div>

        <div className="my-6 text-white w-[100%]">
          <label className="block mb-3">Subject</label>
          <input
            className="border-[1px] border-[#ffffff1a] w-[100%] bg-[#0f172a1a] rounded-lg text-white p-3 outline-0"
            placeholder="Enter Subject"
            type="text"
            required
            name="subject"
          />
        </div>

        <div className="my-6 text-white w-[100%]">
          <label className="block mb-3">Message</label>
          <textarea
            rows="6"
            cols="6"
            className="border-[1px] border-[#ffffff1a] w-[100%] bg-[#0f172a1a] rounded-lg text-white p-3 outline-0"
            placeholder="Enter Your Message"
            required
            name="message"
          ></textarea>
        </div>

        <div className="flex justify-end items-end w-[100%] py-4">
          <input
            type="submit"
            className="hover:border-2 hover:border-[#29C195] bg-[#29C195] text-white hover:bg-white rounded hover:text-[#00C6BF] px-4 py-3 cursor-pointer"
            value="Send Message"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
