import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import myImg from "../../assets/images/me2.jpeg";

const accounts = [
  { icon: FaGithub, link: "https://github.com/FatimaMN85" },
  { icon: FaLinkedin, link: "https://www.linkedin.com/in/fatimamoumni" },
  { icon: FaXTwitter, link: "https://x.com/FatimaMoumni5" },
];

const Intro = () => {
  return (
    <div className="flex flex-col lg:items-center justify-center text-center overflow-x-hidden h-[100vh]">
      <img
        src={myImg}
        alt="profile"
        className="md:w-[200px] md:h-[200px] w-[200px] h-[200px] rounded-full border-4 border-[#00C6BF] mx-auto"
      />

      <h1 className="text-white font-bold  lg:text-6xl text-2xl mt-6 leading-loose">
        <span className="">Hi, I'm </span>
        <ReactTyped
          strings={[" Fatima Moumni"]}
          loop
          typeSpeed={100}
          className="text-[#00C6BF] font-extrabold"
        />
      </h1>
      <h1 className="text-white text-center font-bold leading-loose">
        Full-Stack Web Developer
      </h1>

      <p className="text-white text-2xl my-4 flex justify-center items-center">
        {accounts.map((account, index) => (
          <Link
            key={index}
            to={account.link}
            target="_blank"
            className="mr-3 hover:text-[#00C6BF]"
          >
            <account.icon />
          </Link>
        ))}
      </p>

      <div className="mt-3 lg:w-[50%] flex justify-center items-center">
        <Link
          to="/Projects"
          className="border-2 border-[#00C6BF] transition duration-300 ease-in-out hover:bg-[#00C6BF] rounded text-white p-3"
        >
          View My Work
        </Link>
      </div>
    </div>
  );
};

export default Intro;
