import React from "react";
import { SiMysql, SiExpress, SiSanity, SiFirebase } from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import { BiLogoMongodb } from "react-icons/bi";
import CommonContent from "./CommonContent";

const BackEndSkills = () => {
  const skills = [
    { icon: <DiNodejs />, name: "Node.js" },
    { icon: <SiExpress />, name: "Express" },
    { icon: <SiMysql />, name: "MySQL" },
    { icon: <BiLogoMongodb />, name: "MongoDB" },
    { icon: <SiSanity />, name: "Sanity" },
    { icon: <SiFirebase />, name: "Firebase" },
  ];
  return (
    <div className=" text-white pt-4">
      <h1 className="text-center text-2xl">Back End</h1>
      <CommonContent skills={skills} />
    </div>
  );
};

export default BackEndSkills;
