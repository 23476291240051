import React from "react";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

const SingleProject = ({ data, index }) => {
  return (
    <div
      key={index}
      className="bg-[#ffffff0d] group border-[1px] flex items-center flex-col justify-center p-4 border-[#ffffff1a] rounded-lg"
    >
      <div className="relative">
        <img
          src={data.imgURL}
          alt={data.id}
          className=" h-[380px] object-contain"
        />

        <div className="absolute inset-0 flex items-center justify-center bg-[#0f172acc] bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all duration-500">
          {data.link && (
            <Link
              to={data.link}
              target="_blank"
              className="text-black p-3 bg-[#00C6BF] rounded-full"
            >
              <FaExternalLinkAlt className="text-2xl" />
            </Link>
          )}
        </div>
      </div>
      <div className="p-3 text-white">
        <h3>{data.title}</h3>
        <p className="text-[#bfbecb]">{data.technologies}</p>
      </div>
    </div>
  );
};

export default SingleProject;
