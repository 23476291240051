import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "./Tabs";
import { database } from "../../config/firebase";
import { get, ref } from "firebase/database";
import SingleProject from "./SingleProject";

const MainSection = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const projectsRef = ref(database, "ptojects");

    get(projectsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const projectArr = Object.entries(snapshot.val()).map(
            ([id, data]) => ({
              id,
              ...data,
            })
          );

          // Sort the array by id in ascending order
          projectArr.sort((a, b) => a.id - b.id);

          setProjects(projectArr);
        } else {
          console.log("No data avaliable");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="container m-auto 2xl:pb-14 h-auto">
      <div className="w-[100%] py-20 lg:px-14 px-4 flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center flex-col text-center">
          <h1 className="text-white text-center text-3xl mb-4 font-bold">
            Projects
          </h1>
          <hr className="border-[2px] border-[#00C6BF] w-[90px]" />
        </div>
        <Tabs>
          <Tab label="All">
            <div className="py-8  grid lg:grid-cols-3 md:grid-cols-2 gap-8">
              {projects.map((data, index) => (
                <SingleProject data={data} index={index} />
              ))}
            </div>
          </Tab>
          <Tab label="JavaScript">
            <div className="py-8 grid lg:grid-cols-3 md:grid-cols-2 gap-8">
              {projects.map(
                (data, index) =>
                  data.type === "javascript" && (
                    <SingleProject data={data} index={index} />
                  )
              )}
            </div>
          </Tab>
          <Tab label="React">
            <div className="py-8 grid lg:grid-cols-3 md:grid-cols-2 gap-8">
              {projects.map(
                (data, index) =>
                  data.type === "react" && (
                    <SingleProject data={data} index={index} />
                  )
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default MainSection;
