import React from "react";
import { TbBrandJavascript } from "react-icons/tb";
import { TiHtml5 } from "react-icons/ti";
import { MdCss } from "react-icons/md";
import { SiTailwindcss } from "react-icons/si";
import { BsBootstrapFill } from "react-icons/bs";
import { FaReact } from "react-icons/fa";
import CommonContent from "./CommonContent";

const FrontEndSkills = () => {
  const skills = [
    { icon: <TiHtml5 />, name: "HTML5" },
    { icon: <MdCss />, name: "CSS3" },
    { icon: <SiTailwindcss />, name: "Tailwind CSS" },
    { icon: <BsBootstrapFill />, name: "Bootstrap" },
    { icon: <TbBrandJavascript />, name: "JavaScript" },
    { icon: <FaReact />, name: "React" },
  ];
  return (
    <div className="text-white pt-4">
      <h1 className="text-center text-2xl">Front End</h1>
      <CommonContent skills={skills} />
    </div>
  );
};

export default FrontEndSkills;
