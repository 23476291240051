import React from "react";
import RiseLoader from "react-spinners/RiseLoader";

const Loading = () => {
  return (
    <div className="container lg:px-[20px] px-4 2xl:p-0 mx-auto flex justify-center items-center h-screen">
      <RiseLoader
        color="#00C6BF"
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
