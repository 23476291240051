import React, { Suspense } from "react";
import Navbar from "../components/common/Navbar";
import Loading from "../components/common/Loading";

const MainLayout = ({ children }) => {
  return (
    <div className="relative bg-[#0f172a]">
      <Suspense fallback={<Loading />}>
        <Navbar />
        <main>{children}</main>
      </Suspense>
    </div>
  );
};

export default MainLayout;
