import React from "react";
import { Link } from "react-router-dom";

const AboutMe = () => {
  return (
    <div className="container mx-auto h-auto 2xl:h-[100vh]">
      <div className="w-100 py-20 lg:px-14 px-4 flex flex-col justify-center items-center">
        <div className="flex items-center justify-center flex-col text-center">
          <h1 className="text-white text-center text-3xl mb-4 font-bold">
            About Me
          </h1>
          <hr className="border-[2px] border-[#00C6BF] w-[90px]" />
        </div>

        <div className="lg:flex justify-center pt-8 lg:mt-20">
          <div className="lg:w-[60%] lg:ml-20">
            <h1 className="text-2xl font-extrabold text-[#00C6BF] mb-8">
              Who I am
            </h1>
            <p className="text-white my-4 leading-loose md:mx-auto">
              I'm a full-stack developer who loves creating smooth and effective
              web applications. I work on both the front-end and back-end of
              projects, focusing on making technology solutions that improve
              user experience and tackle complex challenges.
            </p>

            <p className="text-white my-4 leading-loose md:mx-auto">
              My interest in tech started with a curiosity about how websites
              and apps work behind the scenes. What began as curiosity turned
              into a strong passion. I have formal education and practical
              experience, and now I specialize in creating dynamic and
              responsive web solutions.
            </p>

            <p className="text-white my-4 leading-loose md:mx-auto">
              I love solving tough problems and seeing my solutions make a
              difference. Whether it's improving a website's performance or
              adding a new feature, I'm always excited to learn and work with
              new technologies.
            </p>
          </div>

          <div className="lg:w-[30%] text-white lg:ml-20 mt-8 lg:mt-0">
            <h1 className="text-2xl font-extrabold text-[#00C6BF]">
              Personal Information
            </h1>
            <ul className="list-none my-8">
              <li className="my-8">
                <strong className="border-b-[3px] border-[#00C6BF]">
                  Name:
                </strong>
                <span className="ml-3 text-[#6b6b6b]">Fatima Moumni</span>
              </li>
              <li className="my-8">
                <strong className="border-b-[3px] border-[#00C6BF]">
                  Age:
                </strong>{" "}
                <span className="ml-3 text-[#6b6b6b]">25 Years</span>
              </li>
              <li className="my-8">
                <strong className="border-b-[3px] border-[#00C6BF]">
                  Country:
                </strong>{" "}
                <span className="ml-3 text-[#6b6b6b]">Morocco</span>
              </li>
              <li className="my-8">
                <strong className="border-b-[3px] border-[#00C6BF]">
                  City:
                </strong>{" "}
                <span className="ml-3 text-[#6b6b6b]">Agadir</span>
              </li>
              <li className="my-8">
                <strong className="border-b-[3px] border-[#00C6BF]">
                  Freelance:
                </strong>{" "}
                <span className="ml-3 text-[#6b6b6b]">Available</span>
              </li>
            </ul>

            <div className="pt-4">
              <Link
                to="/Contact"
                className="border-2 border-[#00C6BF] transition duration-300 ease-in-out hover:bg-[#00C6BF] rounded text-white p-3"
              >
                Let's Work Together
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
