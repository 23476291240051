import React, { useState } from "react";
import { IoHome } from "react-icons/io5";
import { FaLaptopCode } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { LuClipboardList } from "react-icons/lu";
import { BiMessageAltEdit } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-01.png";
import { HiMiniBars3 } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";

const list = [
  { icon: IoHome, link: "/" },
  { icon: FiUser, link: "/About" },
  { icon: FaLaptopCode, link: "/Skills" },
  { icon: LuClipboardList, link: "/Projects" },
  { icon: BiMessageAltEdit, link: "/Contact" },
];

const Navbar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <nav className="">
      <button
        className="lg:hidden text-[#00C6BF] fixed right-4 top-4 text-4xl"
        onClick={() => setOpen(!open)}
      >
        {open ? <IoMdClose /> : <HiMiniBars3 />}
      </button>

      <div
        className={`bg-[#0b1223] py-4 px-4 border-r-[#013b39] z-50 border-r-[1px] flex flex-col items-center fixed bottom-0 left-0 top-0 lg:block ${
          open ? "block" : "hidden"
        }`}
      >
        <div className="">
          <img src={logo} alt="logo" className="w-[60px]" />
        </div>
        <ul className={`flex flex-col justify-center items-center h-[100vh]`}>
          {list.map((item, index) => (
            <li className="mb-8" key={index}>
              <Link to={item.link}>
                <item.icon
                  className={`text-white rounded-full hover:bg-[#ffffff1a] p-3 text-[50px] ${
                    location.pathname === item.link && "bg-[#ffffff1a]"
                  }`}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
