import React from "react";
import FrontEndSkills from "./FrontEndSkills";
import BackEndSkills from "./BackEndSkills";
import ToolsSkills from "./ToolsSkills";

const MainSection = () => {
  return (
    <div className="container mx-auto">
      <div className="w-[100%] lg:px-14 py-16 px-4 flex flex-col justify-center text-center h-auto 2xl:h-[100vh]">
        <div className="flex items-center justify-center flex-col text-center">
          <h1 className="text-white text-center text-3xl my-4 font-bold">
            Skills
          </h1>
          <hr className="border-[2px] border-[#00C6BF] w-[90px]" />
        </div>

        <div className="grid lg:grid-cols-3 gap-11 lg:ml-16 mt-14 justify-center items-center">
          <FrontEndSkills />

          <BackEndSkills />

          <ToolsSkills />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
