import React from "react";
import MainLayout from "../../layouts/MainLayout";
import Intro from "../../components/home/Intro";

const Home = () => {
  return (
    <MainLayout>
      <Intro />
    </MainLayout>
  );
};

export default Home;
