import React from "react";
import MainLayout from "../../layouts/MainLayout";
import MainSection from "../../components/projects/MainSection";

const Projects = () => {
  return (
    <MainLayout>
      <MainSection />
    </MainLayout>
  );
};

export default Projects;
